import SearchModuleBase from './base.js';

export default class SearchCitiesModule extends SearchModuleBase {
    select = document.getElementById('citiesSelect');
    container = document.getElementById('citiesContainer');
    mainInput = null;
    selectButton = null;
    clearFilterButton = null;
    closeButton = null;
    selectedCities = [];
    maxSetCityCheckboxes = 5;   
    citySelectLoopNumber = 0;
    parent = null;

    constructor(config, notification, parent) {
        super(config, notification, "citiesComponentDisabled");
        this.parent = parent;
        this.selectButton = this.container.querySelector('.button--primary');
        this.mainInput = this.select.querySelector('input[type="text"]');
        this.disabled = this.settings.isApartmentsFromFooterView || this.disabled;
        this.mainInput.disabled = this.disabled;
        this.clearFilterButton = this.container.querySelector('.button--tetriary');
        this.closeButton = this.container.querySelector('.close');
        this.bindOpenCitiesSelectModal();
        this.bindCloseCitiesSelectModal();
        this.bindClearFilterButton();
        this.bindSearchInput();
        this.bindInputChange();
        this.bindCitySelect();
        this.bindCategoriesToggleButtons();
        // this.removeUnnecessaryToggleButtons();
        this.setCheckboxes();
    }

    bindOpenCitiesSelectModal = () => {
        const { select, mainInput } = this;

        select.addEventListener('click', (e) => {
            if (this.disabled) {
                this.notification.show(this.notification.types.error, this.locale.inputCannotBeEdited);
            } else {
                if (
                    e.target == select.querySelector('svg')
                    || e.target == select.querySelector('input[type="text"]')
                ) {
                    this.openModalHandler();
                }
            }
        });

        if (document.body.clientWidth < 901) {
            mainInput.readOnly = true;
        }
    }

    centerPageVerticaly = (container) => {
        const boundingClientRect = container.getBoundingClientRect();
        const containerHeight = boundingClientRect.height;
        const windowHeight = window.innerHeight;
        const heightDiff = windowHeight - containerHeight;

        window.scroll({
            top: heightDiff / 2 + 100,
            left: 0,
            behavior: 'smooth'
        });
    }

    bindCloseCitiesSelectModal = () => {
        const { select, container, mainInput, selectButton, closeButton } = this;

        [container, closeButton].forEach(element => {
            element.addEventListener('click', (e) => {
                if (e.target == container || e.target == selectButton || e.target.parentElement == closeButton) {
                    this.closeModalHandler();
                    this.clearCitiesToggledClass();
                }
            });
        });

        document.body.addEventListener('click', (e) => {
            if (e.target.parentElement !== null && e.target.parentElement.parentElement !== select) {
                mainInput.parentElement.classList.remove('active');
                this.clearCitiesToggledClass();
            }
        });
    }

    openModalHandler = () => {
        const items = this.container.querySelectorAll('label');

        if (document.body.clientWidth < 901) {
            document.body.classList.add('no-overflow');
            document.documentElement.classList.add('no-overflow');
        }
        this.container.classList.remove('search-container--close');
        this.container.classList.add('search-container--open');

        items.forEach(item => {
            const labelText = item.querySelector('span');
            if (labelText) {
                labelText.parentElement.parentElement.style.display = "block";
            }
        });
        this.mainInput?.parentElement.classList.add('active');

        if (document.body.clientWidth > 900 && this.settings.isMainPage) {
            this.centerPageVerticaly(this.container);
        }
    }

    closeModalHandler = () => {
        document.body.classList.remove('no-overflow');
        document.documentElement.classList.remove('no-overflow');
        this.container.classList.remove('search-container--open');
        this.container.classList.add('search-container--close');
    }

    bindClearFilterButton = () => {
        const { clearFilterButton, mainInput } = this;

        clearFilterButton.addEventListener('click', () => {
            const container = this.container.querySelector('.cities-module');
            const checkboxes = container.querySelectorAll('input[type="checkbox"]');
            const citiesGroups = container.querySelectorAll('.cities-module > .cities-group, .cities-module > .cities-row > .cities-group');
            const items = container.querySelectorAll('label');
            const mobileSearchInput = document.querySelector('.search-city input');
            const selectedItemsContainer = container.querySelector('.selected-items');

            checkboxes.forEach(checkbox => {
                checkbox.checked = false;
                checkbox.disabled = false;
            });
            mainInput.value = null;
            mobileSearchInput.value = null;
            citiesGroups.forEach(item => {
                item.classList.remove('hidden');
            });
            container.querySelector('.after-search-type').classList.remove('active');
            items.forEach(item => {
                const labelText = item.querySelector('span');
                labelText.parentElement.parentElement.style.display = "block";
            });
            selectedItemsContainer.innerHTML = "";
            this.selectedCities = [];
            this.selectedCities.length = 0;
            this.citySelectLoopNumber = 0;
            this.updateCityInput([]);
            this.clearCitiesToggledClass();
        });
    }

    bindInputChange = () => {
        const { mainInput } = this;
        const container = this.container.querySelector('.cities-module');
        const items = container.querySelectorAll('label');

        mainInput.addEventListener('input', () => {
            const pattern = mainInput.value;
            const citiesGroups = container.querySelectorAll('.cities-module > .cities-group, .cities-module > .cities-row > .cities-group');

            if (pattern.length !== 0) {
                citiesGroups.forEach(item => {
                    item.classList.add('hidden');
                });
                container.querySelector('.after-search-type').classList.add('active');
            } else {
                citiesGroups.forEach(item => {
                    item.classList.remove('hidden');
                });
                container.querySelector('.after-search-type').classList.remove('active');
            }

            items.forEach(item => {
                const labelText = item.querySelector('span');
                const reg = new RegExp(`${pattern}.*`, "i");

                if (!reg.test(labelText.innerHTML)) {
                    labelText.parentElement.parentElement.style.display = "none";
                } else {
                    labelText.parentElement.parentElement.style.display = "block";
                }
            });
        });

        mainInput.addEventListener('focus', () => {
            mainInput.value = '';
        });

        mainInput.addEventListener('focusout', () => {
            this.updateCityInput();
        });
    }

    bindCitySelect = () => {
        const { container } = this;
        const selectedItemsContainer = container.querySelector('.selected-items');
        const citiesModule = container.querySelector('.cities-module');
        const items = citiesModule.querySelectorAll('.cities-group label');
        const _items = citiesModule.querySelectorAll('.after-search-type label');
        let checkboxes = container.querySelectorAll('[type="checkbox"]');

        [...items, ..._items].forEach(item => {
            item.addEventListener('click', (e) => {
                if (e.srcElement.tagName != 'INPUT') {
                    return;
                }

                if (this.parent !== null) {
                    this.parent.onCitiesChanged();
                }

                const checkbox = item.querySelector('[type="checkbox"]');
                const cityName = item.querySelector('span').innerHTML;
                const duplicates = container.querySelectorAll(`input[value="${checkbox.value}"]`);

                if (this.selectedCities.length < this.maxSetCityCheckboxes) {
                    if (checkbox.checked) {
                        if (!this.selectedCities.includes(cityName)) {
                            const checkbox = item.parentElement.cloneNode(true);
                            checkbox.addEventListener('click', () => {
                                this.clickCityCheckboxSelectHandler(checkbox, cityName);
                            });
                            selectedItemsContainer.appendChild(checkbox);
                            this.selectedCities.push(cityName);
                        }
                    } else {
                        const selectedItem = selectedItemsContainer.querySelector(`input[value="${checkbox.value}"]`);
                        selectedItem.parentElement.parentElement.remove();
                        this.selectedCities = this.selectedCities.filter(value => {
                            return value !== cityName;
                        });
                    }

                    duplicates.forEach(duplicate => {
                        duplicate.checked = checkbox.checked;
                    });

                    if (this.selectedCities.length == this.maxSetCityCheckboxes) {
                        checkboxes.forEach(item => {
                            if (!item.checked) {
                                item.disabled = true;
                            }
                        });

                        this.showCityNotification();
                    }
                } else {
                    if (checkbox.checked) {
                        checkbox.checked = false;
                    } else {
                        const selectedItem = selectedItemsContainer.querySelector(`input[value="${checkbox.value}"]`);
                        selectedItem.parentElement.parentElement.remove();
                        this.selectedCities = this.selectedCities.filter(value => {
                            return value !== cityName;
                        });

                        checkboxes.forEach(item => {
                            item.disabled = false;
                        });
                    }

                    duplicates.forEach(duplicate => {
                        duplicate.checked = checkbox.checked;
                    });
                }

                if (this.citySelectLoopNumber === 0) {
                    this.citySelectLoopNumber = 1;
                } else {
                    this.citySelectLoopNumber = 0;
                }
                this.updateCityInput(this.selectedCities);
            });
        });
    }

    updateCityInput = (cities = null) => {
        const { mainInput, select } = this;
        const { locale } = this;
        let { searchBarContainer } = this;

        if (cities !== null) {
            this.selectedCities = [...new Set(cities)];
        }

        select.querySelectorAll('.cities-counter').forEach(counter => counter.remove());

        if (this.selectedCities.length === 0) {
            mainInput.value = '';
        } else if (this.selectedCities.length === 1) {
            mainInput.value = this.selectedCities[0];
        } else {
            mainInput.value = locale.manyCities;
            const span = document.createElement('span');
            span.classList.add('cities-counter');
            span.innerText = this.selectedCities.length;
            mainInput.parentElement.appendChild(span);
        }

        if (searchBarContainer !== null) {
            searchBarContainer.querySelector('p > span').innerHTML = mainInput.value !== '' ? mainInput.value : locale.selectCity;
        }
    }

    setCheckboxes = () => {
        const { container } = this;
        const selectedItemsContainer = container.querySelector('.selected-items');
        const citiesModule = container.querySelector('.cities-module');
        const items = citiesModule.querySelectorAll('label');
        let checkboxes = container.querySelectorAll('[type="checkbox"]');

        items.forEach(item => {
            const checkbox = item.querySelector('[type="checkbox"]');
            const cityName = item.querySelector('span').innerHTML;

            if (this.selectedCities.length < this.maxSetCityCheckboxes) {
                if (checkbox.checked) {
                    if (!this.selectedCities.includes(cityName)) {
                        const checkbox = item.parentElement.cloneNode(true);
                        checkbox.addEventListener('click', () => {
                            this.clickCityCheckboxSelectHandler(checkbox, cityName);
                        });
                        selectedItemsContainer.appendChild(checkbox);
                        this.selectedCities.push(cityName);
                    }
                }
            }
            else {
                if (checkbox.checked) {
                    if (this.selectedCities.includes(cityName)) {
                        checkbox.checked = true;
                    } else {
                        checkbox.checked = false;
                    }
                }
            }
        });

        if (this.selectedCities.length == this.maxSetCityCheckboxes) {
            checkboxes.forEach(item => {
                if (!item.checked) {
                    item.disabled = true;
                }
            });
        }

        this.updateCityInput(this.selectedCities);
    }

    getCities = () => {
        const container = this.container.querySelector('.cities-module');
        const checkboxes = container.querySelectorAll('input[type="checkbox"]');
        const selectedCities = [].filter.call(checkboxes, function (checkbox) {
            return checkbox.checked;
        });

        let ids = [];
        selectedCities.forEach(checkbox => {
            if (!ids.includes(checkbox.value)) {
                ids.push(checkbox.value);
            }
        });

        return ids;
    }

    bindSearchInput = () => {
        const input = document.querySelector('.search-city input');
        const closeButton = document.querySelector('.search-city .input svg:last-child');
        const container = this.container.querySelector('.cities-module');

        input.addEventListener('input', () => {
            const cityName = input.value;
            const cities = document.querySelectorAll('.cities-group li label span');

            if (cityName.length !== 0) {
                container.classList.add('active-search');
            } else {
                container.classList.remove('active-search');
            }

            closeButton.style.display = cityName !== "" ? "block" : "none";
            cities.forEach(city => {
                city.parentElement.parentElement.style.display = city.innerText.toLowerCase().match(cityName.toLowerCase()) ? "unset" : "none";
            });
        });

        closeButton.addEventListener('click', () => {
            input.value = "";
            input.dispatchEvent(new Event('input'));
        });
    }

    bindCategoriesToggleButtons = () => {
        const buttons = document.querySelectorAll('.cities-group .toggle');

        buttons.forEach(button => {
            button.remove();
            // ! pozostałość po funkcji pokaż mniej/więcej
            // button.addEventListener('click', () => {
            //     const items = button.parentElement.querySelectorAll('ul li');
            //     items.forEach((item, index) => {
            //         if (index > 2) {
            //             item.style.cssText = button.classList.contains('toggled') ? "display: none !important" : "display: block !important";
            //         }
            //     });

            //     button.innerText = button.classList.contains('toggled') ? this.locale.showMore : this.locale.showLess;
            //     button.classList.toggle('toggled');
            // });
        });
    }

    removeUnnecessaryToggleButtons = () => {
        // ! pozostałość po funkcji pokaż mniej/więcej
        // const buttons = document.querySelectorAll('.cities-group .toggle');

        // buttons.forEach(button => {
        //     const items = button.parentElement.querySelectorAll('ul li');

        //     if (items.length <= 3) {
        //         button.remove();
        //     }
        // });
    }

    showCityNotification = () => {
        const searchCity = document.querySelector('.search-city');
        const searchContainer = document.querySelector('.search-container__background');

        if (searchContainer.querySelector('.alert')) {
            return;
        }

        const notify = this.notification.createHtml(this.locale.selectedMaxCitiesInSearch);
        searchContainer.insertBefore(notify, searchCity);

        setTimeout(() => {
            notify.remove();
        }, 3000);
    }

    clearCitiesToggledClass = () => {
        document.querySelectorAll('.cities-group .toggled').forEach(item => {
            item.classList.remove('toggled');
            item.innerText = this.locale.showMore;
        });
    }

    clickCityCheckboxSelectHandler = (checkbox, cityName) => {
        const checkboxes = this.container.querySelectorAll('[type="checkbox"]');
        const value = checkbox.querySelector('input[type="checkbox"]').value;
        const inputsFromCities = this.container.querySelectorAll(`input[value="${value}"]`);
        inputsFromCities.forEach(input => {
            input.checked = false;
        });

        this.selectedCities = this.selectedCities.filter(value => {
            return value !== cityName;
        });
        checkbox.remove();
        checkboxes.forEach(item => {
            item.disabled = false;
        });
        this.updateCityInput(this.selectedCities);
    }
}