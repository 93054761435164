require("regenerator-runtime");
require("jquery");
require("popper.js");
require("bootstrap");

import Moment from "../../utilities/moment.js";
import SearchCitiesModule from "./sections/cities.js";
import SearchDatesModule from "./sections/dates.js";
import SearchPeopleModule from "./sections/people.js";
import SearchFeaturesModule from "./sections/features.js";

export default class ApartmentSearch {
    searchBarContainer = document.querySelector('.search-header');
    featuresContainer = document.getElementById('featuresContainer');
    mainContainer = document.querySelector(".search-box");
    filtersContainer = document.querySelector(".objects-filters-container");
    notification = null;
    subsearch = null;
    isForApartments = false;
    isBundle = false;
    config = null;
    loader = null;

    constructor(config, notification, loader, isForApartments, isBundle = false, subsearch = null) {
        this.notification = notification;
        this.subsearch = subsearch;
        this.isForApartments = isForApartments;
        this.isBundle = isBundle;
        this.locale = config.locale;
        this.config = config;
        this.loader = loader;
        this.moment = new Moment();
        this.cityModule = new SearchCitiesModule(config, notification, this);
        this.dateModule = new SearchDatesModule(config, notification);
        this.peopleModule = new SearchPeopleModule(config, notification);
        if (this.featuresContainer !== null) {
            this.featuresModule = new SearchFeaturesModule(config, notification);
        }

        this.bindSearchButtons();
        this.bindFiltersCallback();
        this.bindCloseModalOnBodyClick(
            this.cityModule.container,
            this.cityModule.select
        );
        this.bindCloseModalOnBodyClick(
            this.dateModule.container,
            this.dateModule.select
        );
        this.bindCloseModalOnBodyClick(
            this.peopleModule.container,
            this.peopleModule.select
        );
        this.bindMobileSearchBar();
        this.hideLoader();
    }

    bindCloseModalOnBodyClick = (container, select) => {
        const cityModule = this.cityModule;
        const dateModule = this.dateModule;
        const peopleModule = this.peopleModule;

        container.addEventListener("click", (e) => {
            e.stopPropagation();
        });

        document.body.addEventListener("click", (e) => {
            if (
                e.target.parentElement !== null &&
                e.target.parentElement.parentElement !== select &&
                e.target !== cityModule.searchBarButton &&
                e.target !== dateModule.searchBarButton &&
                e.target !== peopleModule.searchBarButton &&
                container.classList.contains("search-container--open") &&
                document.body.clientWidth > 900
            ) {
                container.classList.remove("search-container--open");
                container.classList.add("search-container--close");
            }
        });
    };

    bindMobileSearchBar = () => {
        this.searchBarContainer?.addEventListener('click', () => {
            this.mainContainer.classList.add('active');
            this.searchBarContainer.classList.add('hidden');
            this.filtersContainer.classList.add('hidden');
            this.resizeMobileObjectsContainers();
        });
    }

    bindSearchButtons = () => {
        const mainButton = document.querySelector(".apartment-search-button");

        mainButton.addEventListener("click", () => {
            this.searchApartment();
        });
    };

    bindFiltersCallback = () => {
        if (this.subsearch !== null) {
            this.subsearch.setFilterCallback(() => {
                if (window.innerWidth < 901) {
                    this.searchApartment();
                }
            });
        }
    }
    onCitiesChanged = () => {
        if (this.subsearch !== null) {
            //            this.subsearch.clearPrices();
        }
    }

    searchApartment = async (priceSorting = undefined) => {
        if (priceSorting !== undefined) {
            let cookieData = "";
            if (priceSorting === "desc") {
                cookieData = "up;";
            } else {
                cookieData = "down;";
            }

            let date = new Date();
            date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
            let expires = "expires=" + date.toUTCString() + ";";
            document.cookie = "priceSorter=" + cookieData + expires + "path=/;Secure;SameSite=None; Partitioned;";
        }
        const turistsData = this.peopleModule.getTuristsData();
        const dateRange = this.dateModule.getDateRangeObject();
        const cities = this.cityModule.getCities();

        if (cities.length === 0) {
            this.notification.show(
                this.notification.types.error,
                this.locale.cityValidation
            );
        } else if (
            this.moment.m(dateRange.finish) <= this.moment.m(dateRange.start)
        ) {
            if (this.moment.m(dateRange.finish) < this.moment.m(dateRange.start)) {
                this.notification.show(
                    this.notification.types.error,
                    this.locale.dateValidation
                );
            } else {
                this.notification.show(
                    this.notification.types.error,
                    this.locale.sameDateSelected
                );
            }
        } else if (dateRange.start === "" || dateRange.finish === undefined) {
            this.notification.show(
                this.notification.types.error,
                this.locale.dateValidation
            );
        } else {
            const turists = `${turistsData.adults}-${turistsData.kids}-${turistsData.kidsOptions}`;
            let queryParams = {};
            let originalParams = new URLSearchParams(window.location.search);
            let aFeaturesFromURL = [];
            let fFeaturesFromURL = [];
            for (const [skey, svalue] of originalParams) {
                if (skey === "aFeatures") {
                    aFeaturesFromURL.push(svalue);
                }
                if (skey === "fFeatures") {
                    fFeaturesFromURL.push(svalue);
                }
            }
            queryParams["queryParams"] = "";
            queryParams["facilities"] = [];
            let queryParamsSearch = "";
            if (this.subsearch !== null) {
                let queryParamsSubsearch = this.subsearch.getQueryParams();
                queryParamsSearch = queryParamsSubsearch["queryParams"];
                queryParams["facilities"] = queryParamsSubsearch["facilities"];
            }
            let aFeaturesSearch = [];
            let fFeaturesSearch = [];
            let fOtherSearch = [];
            let searchParams = new URLSearchParams(queryParamsSearch);
            for (const [skey, svalue] of searchParams) {
                if (skey === "aFeatures") {
                    aFeaturesSearch.push(svalue);
                }
                else if (skey === "fFeatures") {
                    fFeaturesSearch.push(svalue);
                }
                else {
                    fOtherSearch.push(skey + "=" + svalue);
                }
            }
            aFeaturesFromURL.forEach(f => {
                if (!aFeaturesSearch.includes(f)) {
                    if (!this.isForApartments) {
                        aFeaturesSearch.push(f);
                    }
                }
            });
            fFeaturesFromURL.forEach(f => {
                if (!fFeaturesSearch.includes(f)) {
                    if (this.isForApartments) {
                        fFeaturesSearch.push(f);
                    }
                }
            });
            aFeaturesSearch.forEach(function (part, index) {
                aFeaturesSearch[index] = "aFeatures=" + part;
            });
            fFeaturesSearch.forEach(function (part, index) {
                fFeaturesSearch[index] = "fFeatures=" + part;
            });
            let queryParts = [];
            if (aFeaturesSearch.length > 0) {
                let sFeat = aFeaturesSearch.join("&");
                queryParts.push(sFeat);
            }
            if (fFeaturesSearch.length > 0) {
                let sFeat = fFeaturesSearch.join("&");
                queryParts.push(sFeat);
            }
            if (fOtherSearch.length > 0) {
                let sOther = fOtherSearch.join("&");
                queryParts.push(sOther);
            }
            queryParams["queryParams"] = queryParts.join("&");
            if (queryParams["queryParams"] !== "") {
                queryParams["queryParams"] = "?" + queryParams["queryParams"];
            }
            let get = "";
            if (this.isForApartments && !this.isCitiesChange()) {
                //apartments
                //                location.href = `/Search/Facility/${this.settings("regionId")}/${this.settings("apartmentId")}/${this.settings("facilityId")}/${this.searchSettings.dateFrom}/${this.searchSettings.dateTo}/${turists}/0`;
                if (this.isBundle) {
                    get =
                        `${this.config["settings"]["langPrefix"]}/bundle/${this.config["settings"]["bundleId"]}/${this.config["settings"]["facilityId"]}/${dateRange.start}/${dateRange.finish}/${turists}` +
                        queryParams["queryParams"];
                } else {
                    get =
                        `${this.config["settings"]["langPrefix"]}/search/facility/${this.config["settings"]["cityId"]}/${this.config["settings"]["facilityId"]}/${dateRange.start}/${dateRange.finish}/${turists}` +
                        queryParams["queryParams"];
                }
            } else {
                //facilities
                get =
                    `${this.config["settings"]["langPrefix"]}/search/${cities.join("-")}/${dateRange.start}/${dateRange.finish
                    }/${turists}` + queryParams["queryParams"];
            }

            if (this.featuresContainer !== null) {
                get += this.featuresModule.getFeaturesQuery();
            }

            if (this.config.settings.isApartmentsFromFooterView) {
                get =
                    `/search/checkapartment/${this.config["settings"]["apartmentId"]}/${this.config["settings"]["facilityId"]}/${dateRange.start}/${dateRange.finish}/${turists}`;

                const response = await fetch(get, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'X-Requested': 'With: XMLHttpRequest'
                    }
                });

                const res = await response.json();

                if (res == "0") {
                    get =
                        `${this.config["settings"]["langPrefix"]}/search/facility/${this.config["settings"]["cityId"]}/${this.config["settings"]["facilityId"]}/${dateRange.start}/${dateRange.finish}/${turists}?showPopup=true` +
                        queryParams["queryParams"];
                } else {
                    get =
                        `${this.config["settings"]["langPrefix"]}/search/apartment/${this.config["settings"]["apartmentId"]}/${this.config["settings"]["facilityId"]}/${dateRange.start}/${dateRange.finish}/${turists}` +
                        queryParams["queryParams"];
                }
            }
            
            this.loader.show();
            window.location = get;
        }
    };

    triggerSearchBySort = async (priceSorting) => {
        this.searchApartment(priceSorting);
    };

    isCitiesChange = () => {
        const cityBefore = this.config["settings"]["cityId"];
        const selectedCities = this.cityModule.getCities();

        if (
            cityBefore === undefined
            || (selectedCities.length === 1 && parseInt(selectedCities[0]) === cityBefore)
        ) {
            return false;
        }

        return true;
    }

    hideLoader = () => {
        window.addEventListener("pageshow", (e) => {
            if (e.persisted) {
                this.loader.hide();
            }
        });
        window.addEventListener("pagehide", () => {
            this.loader.hide();
        });
    }

    resizeMobileObjectsContainers = () => {
        const header = document.querySelector('.header');
        const searchBox = document.querySelector('.search-box');
        const objectsData = document.querySelector('.objects__data');
        const objectsMap = document.querySelector('.objects__map');

        if (!objectsData || !objectsMap) {
            return;
        }

        let height = window.innerHeight - searchBox.offsetHeight - header.offsetHeight;

        if (height <= 400) {
            height = '100vh';
        } else {
            height += "px";
        }

        objectsData.style.height = document.body.clientWidth <= 900 ? height : null;
        objectsMap.style.height = document.body.clientWidth <= 900 ? height : null;
    }
}
